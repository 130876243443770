const entities = '[VIDEOREVIEW]';

const action = {
  SEND_VIDEOREVIEW_REQ: `${entities} SEND_VIDEOREVIEW_REQ`,
  SEND_VIDEOREVIEW_SUC: `${entities} SEND_VIDEOREVIEW_SUC`,
  SEND_VIDEOREVIEW_FAIL: `${entities} SEND_VIDEOREVIEW_FAIL`,

  GET_VIDEOREVIEWS_REQ: `${entities} GET_VIDEOREVIEWS_REQ`,
  GET_VIDEOREVIEWS_SUC: `${entities} GET_VIDEOREVIEWS_SUC`,
  GET_VIDEOREVIEWS_FAIL: `${entities} GET_VIDEOREVIEWS_FAIL`,

  DLT_VIDEOREVIEW_REQ: `${entities} DLT_VIDEOREVIEW_REQ`,
  DLT_VIDEOREVIEW_SUC: `${entities} DLT_VIDEOREVIEW_SUC`,
  DLT_VIDEOREVIEW_FAIL: `${entities} DLT_VIDEOREVIEW_FAIL`,

  EDIT_VIDEOREVIEW_REQ: `${entities} EDIT_VIDEOREVIEW_REQ`,
  EDIT_VIDEOREVIEW_SUC: `${entities} EDIT_VIDEOREVIEW_SUC`,
  EDIT_VIDEOREVIEW_FAIL: `${entities} EDIT_VIDEOREVIEW_FAIL`,

  SEARCH_VIDEOREVIEW_LOCAL_REQ: `${entities} SEARCH_VIDEOREVIEW_LOCAL_REQ`,
  SEARCH_VIDEOREVIEW_LOCAL_SUC: `${entities} SEARCH_VIDEOREVIEW_LOCAL_SUC`,
  SEARCH_VIDEOREVIEW_LOCAL_FAIL: `${entities} SEARCH_VIDEOREVIEW_LOCAL_FAIL`,

  sendVideoReviewReq: (payload) => ({
    type: action.SEND_VIDEOREVIEW_REQ,
    payload,
  }),

  getVideoReviewsReq: (payload) => ({
    type: action.GET_VIDEOREVIEWS_REQ,
    payload,
  }),

  deleteVideoReviewReq: (payload) => ({
    type: action.DLT_VIDEOREVIEW_REQ,
    payload,
  }),

  editVideoReviewReq: (id, data) => ({
    type: action.EDIT_VIDEOREVIEW_REQ,
    id,
    data,
  }),
  searchVideoReviewReq: (data) => ({
    type: action.SEARCH_VIDEOREVIEW_LOCAL_REQ,
    data,
  }),
};

export default action;
