import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import image from '../assets/images/saptarishi-newlogo.png';
import login from '../assets/images/login2.jpg';
import AlertItemWithIcon from '../components/Alert/AlertItemWithIcon';
import LoaderButton from '../components/LoaderButton/LoaderButton';
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
} from '../constant';
import authAction from '../pages/authentication/redux/actions';

const Logins = (_props) => {
  const { register, errors, handleSubmit } = useForm();

  const [togglePassword, setTogglePassword] = useState(false);
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const loginWithJwt = (data) => {
    dispatch(authAction.login(data));
  };

  if (auth && auth.isLoggedIn) {
    const { location } = _props;
    const { state } = location;
    // go to state.from if set before
    if (state && state.from) {
      // window.history.replace(state.from);
    }
  }

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="6">
          <div className="d-flex items-center justify-center h-100 ">
            <img
              src={login}
              alt="login"
              className="img-fluid object-fit h-75 w-100 mt-5"
            />
          </div>
        </Col>
        <Col xs="6" className="">
          <div className="login-card ">
            <div className="login-main">
              <div>
                <a className="logo py-3 m-0 " href="/login">
                  <img className="img-fluid " width={280} src={image} alt="" />
                </a>
              </div>
              <div className=" login-tab">
                <Form
                  className="theme-form fade-show"
                  onSubmit={handleSubmit(loginWithJwt)}
                >
                  <h4>Sign In</h4>
                  <p>{'Enter your email & password to login'}</p>
                  {auth?.statusCode ? (
                    <AlertItemWithIcon
                      status={auth?.statusCode}
                      message={auth?.message}
                    />
                  ) : null}
                  <FormGroup>
                    <Label className="col-form-label">Username</Label>
                    <Input
                      className="form-control"
                      name="username"
                      type="text"
                      innerRef={register({
                        required: 'Enter username or email.',
                      })}
                      placeholder="Enter username or email"
                    />
                    {errors?.username?.type === 'required' ? (
                      <div className="text-danger p-2">
                        This field is required.
                      </div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      name="password"
                      className="form-control"
                      type={togglePassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      innerRef={register({
                        required: 'Enter password.',
                      })}
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? '' : 'show'}></span>
                    </div>
                    {errors?.password?.type === 'required' ? (
                      <div className="text-danger p-2">
                        This field is required.
                      </div>
                    ) : null}
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    {/* <a className="link" href="#javascript">
                      {ForgotPassword}
                    </a> */}
                    <LoaderButton
                      title="Login"
                      color="success"
                      className={`btn-block mt-5`}
                      disabled={auth?.loading || auth?.fetchingCurrentUser}
                      type="submit"
                      isLoading={auth?.loading || auth?.fetchingCurrentUser}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Logins);
