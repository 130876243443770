import { call, put, all, fork, delay, takeEvery } from 'redux-saga/effects';
import {
  getUserProfile,
  updateProfilePic,
  uploadProfilePic,
  getUserProfilePic,
  uploadCoverPicApi,
} from '../../../../api/fetchUser';
import actions from './actions';
import { message } from 'antd';
import { createUserApi, getSytemUsers } from 'api/fetchUser/user';

function* callCreateVendorUserReq(action) {
  try {
    let apiResponse = yield call(createUserApi, action.payload);
    let { status } = apiResponse;
    message.success('New User Added Successfully');
    if (status === 200 || status === 204) {
      yield put({
        type: actions.CREATE_VENDOR_USER_SUC,
        statusCode: status,
        payload: 'New User account created Successfully',
      });
      action?.cb && action.cb();
    } else {
      throw new Error('Failed to create User');
    }
  } catch (err) {
    console.log(err.message)
    yield put({
      type: actions.CREATE_VENDOR_USER_FAIL,
      payload:
        err?.message ??
        'Vendor User account creation Failed',
    });
  }
}

function* fetchVendorUsersReq(action, id) {
  try {
    let apiResponse = yield call(getSytemUsers, action.payload);
    let { data, status } = apiResponse;
    yield put({
      type: actions.GET_VENDOR_USERS_SUC,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: actions.GET_VENDOR_USERS_FAIL,
      payload: err?.response?.data?.message || 'Failed to get System Users',
    });
  }
}

export function* createVendorUser() {
  yield takeEvery(actions.CREATE_VENDOR_USER_REQ, callCreateVendorUserReq);
}

export function* fetchVendorUsers() {
  yield takeEvery(actions.GET_VENDOR_USERS_REQ, fetchVendorUsersReq);
}

export default function* VendorUsersSaga() {
  return yield all([
    fork(createVendorUser),
    fork(fetchVendorUsers),

    //
  ]);
}
