import axios from "../axios";

export const postPageDataApi = (options) => {
    return axios.post('/pages', options);
}

export const postAppSettingApi = (options) => {
    return axios.post('/settings', options);
}
export const getAppSettingApi = (options) => {
    return axios.get('/settings', options);
}

export const getSingleAppSettingApi = (id) => {
    return axios.get(`/settings/${id}`);
}

export const editAppSettingApi = (id, data) => {
    return axios.put(`/settings/${id}`, data);
}
export const deleteAppSettingApi = (id) => {
    return axios.delete(`/settings/${id}`);
}

