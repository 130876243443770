import axios from "../axios";

export const postVariantApi = (options) => {
    return axios.post('/variants', options);
}

export const getVariantApi = (options) => {
    return axios.get('/variants', options);
}
export const deleteVariantApi = (id) => {
    return axios.delete(`/variants/${id}`);
}

export const editVariantApi = (id, data) => {
    return axios.patch(`/variants/${id}`, data);
}