import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'utils';
import {
  getDeliveryChargesApi,
  deleteDeliveryChargeApi,
  editDeliveryChargeApi,
  postDeliveryChargesApi,
} from '../../../../api/delivery-region-charges';
import ShowMessage from '../../../../components/Toast/Toast';
import { delay } from '../../../../helper/utility';
import actions from './actions';

function* callSendDeliveryChargeReq(action) {
  try {
    let apiResponse = yield call(postDeliveryChargesApi, action.payload);

    let { data, status } = apiResponse;

    yield put({
      type: actions.SEND_DELIVERY_CHARGE_SUC,
      statusCode: status,
      payload: data,
    });
    delay(1000);
    yield ShowMessage(status, 'Delivery charge added successfully.');
    history.push('/shippings/delivery-charges');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.SEND_DELIVERY_CHARGE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.SEND_DELIVERY_CHARGE_FAIL,
      payload: err.message,
    });
  }
}

function* callFetchDeliveryChargeReq(action) {
  try {
    let apiResponse = yield call(getDeliveryChargesApi, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.GET_DELIVERY_CHARGES_SUC,
      statusCode: status,
      data: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_DELIVERY_CHARGES_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_DELIVERY_CHARGES_FAIL,
      payload: err.message,
    });
  }
}
function* callDeleteDataReq(action) {
  try {
    let apiResponse = yield call(deleteDeliveryChargeApi, action.payload);

    let { status } = apiResponse;

    yield put({
      type: actions.DLT_DELIVERY_CHARGE_SUC,
      statusCode: status,
      id: action.payload,
    });
    delay(1000);
    yield ShowMessage(status, 'Delivery Charge deleted successfully.');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.DLT_DELIVERY_CHARGE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.DLT_DELIVERY_CHARGE_FAIL,
      payload: err.message,
    });
  }
}
function* callEditDeliveryChargeReq(action) {
  try {
    let apiResponse = yield call(editDeliveryChargeApi, action.id, action.data);

    let { status } = apiResponse;
    const message = 'Delivery Charge edited successfully.';
    yield put({
      type: actions.EDIT_DELIVERY_CHARGE_SUC,
      statusCode: status,
      message,
    });
    delay(1000);
    yield ShowMessage(status, message);
    history.push('/shippings/delivery-charges');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_DELIVERY_CHARGE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_DELIVERY_CHARGE_FAIL,
      payload: err.message,
    });
  }
}

function* callSearchLocalDataRequest(action) {
  try {
    yield delay(400);

    yield put({
      type: actions.SEARCH_DELIVERY_CHARGE_LOCAL_SUC,
      data: action.data,
    });
  } catch (error) {
    yield put({
      type: actions.SEARCH_DELIVERY_CHARGE_LOCAL_FAIL,
      data: 'Error occured while searching Delivery Charges',
    });
    yield ShowMessage(400, 'Error occured while searching Delivery Charges');
  }
}

export function* fetchDeliveryCharge() {
  yield takeEvery(actions.GET_DELIVERY_CHARGES_REQ, callFetchDeliveryChargeReq);
}

export function* sendData() {
  yield takeEvery(actions.SEND_DELIVERY_CHARGE_REQ, callSendDeliveryChargeReq);
}

export function* deleteData() {
  yield takeEvery(actions.DLT_DELIVERY_CHARGE_REQ, callDeleteDataReq);
}
export function* editData() {
  yield takeEvery(actions.EDIT_DELIVERY_CHARGE_REQ, callEditDeliveryChargeReq);
}

export function* searchLocalData() {
  yield takeEvery(
    actions.SEARCH_DELIVERY_CHARGE_LOCAL_REQ,
    callSearchLocalDataRequest,
  );
}

export default function* deliveryChargeSaga() {
  return yield all([
    fork(sendData),
    fork(fetchDeliveryCharge),
    fork(deleteData),
    fork(editData),
    fork(searchLocalData),
  ]);
}
