import { message } from 'antd';
import { getAllCategories } from 'api/fetchCategoryVariant';
import { createBrowserHistory } from 'history';
import APP_CONFIG from '../config/app';
// import NoImage from '../assets/images/noImg.png'

export const history = createBrowserHistory();

export const fileURLReader = (url) => {
  if (!url || url.includes('avatar')) return null;
  if (url?.includes(APP_CONFIG.httpAssetURL)) {
    return url;
  }
  if (!url?.includes('asset/upload') && !url?.includes('/product')) {
    return `${APP_CONFIG.assetURLcategory}${url}`;
  }
  if (url?.includes('asset/upload')) {
    return `${APP_CONFIG.baseURL}${url}`;
  }
  if (url?.includes('/product')) {
    return `${APP_CONFIG.assetURL}${url}`;
  }
};

export const allCategoryList = (list, firstOption) => {
  let allCategoryList = [];
  if (!firstOption) {
    allCategoryList = [{ label: 'Select Category', value: -1 }];
  }
  list.map((main) => {
    if (main?.children?.length) {
      allCategoryList.push({ value: main.id, label: main.name });
      main.children.map((child1) => {
        if (child1?.children?.length) {
          allCategoryList.push({ value: child1.id, label: child1.name });
          child1.children.map((child2) => {
            if (child2?.children?.length) {
              allCategoryList.push({ value: child2.id, label: child2.name });
              child2.children.map((child3) => {
                if (child3?.children?.length) {
                  allCategoryList.push({
                    value: child3.id,
                    label: child3.name,
                  });
                  child3.children.map((child4) => {
                    allCategoryList.push({
                      label: child4.name,
                      value: child4.id,
                    });
                  });
                } else {
                  allCategoryList.push({
                    label: child3.name,
                    value: child3.id,
                  });
                }
              });
            } else {
              allCategoryList.push({ label: child2.name, value: child2.id });
            }
          });
        } else {
          allCategoryList.push({ label: child1.name, value: child1.id });
        }
      });
    } else {
      allCategoryList.push({ label: main.name, value: main.id });
    }
  });
  return allCategoryList;
};

// export const GetParentCatList=(list)=> {
//   let allCategoryList =[];
//   list.map((main)=> {
//     if(main.parentId === "-")
//       allCategoryList.push(main);
//   })
//   return allCategoryList
// }

// export const GetChildCatList=(list)=> {
//   let allCategoryList =[];
//   list.map((main)=> {
//     if(main?.children?.length){
//       // allCategoryList.push(main)
//       main.children.map((child1)=> {
//         if(child1?.children?.length){
//           allCategoryList.push(child1)
//             child1.children.map((child2)=>{
//               if(child2?.children?.length){
//                 allCategoryList.push(child2)
//                   child2.children.map((child3)=> {
//                     if(child3?.children?.length){
//                 allCategoryList.push(child3)
//                         child3.children.map((child4)=> {
//                             allCategoryList.push(child4)
//                         })
//                     }
//                     else{
//                       allCategoryList.push(child3)
//                     }
//                   })
//               }
//               else{
//                 allCategoryList.push(child2)
//               }
//           })
//         }
//         else{
//           allCategoryList.push(child1)
//         }
//       })
//     }

//   })
//   return allCategoryList
// }

export const getCategoryTree = (id, categoryList) => {
  let tree = [];
  let parentId = id;
  function getId() {
    for (let category of categoryList) {
      if (category.id === parentId) {
        tree.unshift(category.id);
        parentId = category.parentId;
        if (parentId !== 0) {
          getId();
        }
      }
    }
  }
  getId();
  return tree;
};
