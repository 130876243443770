import actions from './actions';

const initialState = {
  addUser: { loading: false },
  users: [],
  count: 0,
  loading: false,
  message: '',
  profile: [],
  image: '',
  coverImage: '',
};

const VendorUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    // get users
    case actions.GET_VENDOR_USERS_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_VENDOR_USERS_SUC:
      return {
        ...state,
        loading: false,
        users: action.payload?.users,
        count: action.payload?.count,
      };
    case actions.GET_VENDOR_USERS_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    // create user
    case actions.CREATE_VENDOR_USER_REQ:
      return {
        ...state,
        addUser: { loading: true, message: '' },
      };
    case actions.CREATE_VENDOR_USER_SUC:
      return {
        ...state,
        addUser: { loading: false },
      };
    case actions.CREATE_VENDOR_USER_FAIL:
      return {
        ...state,
        addUser: { loading: false, message: action.payload },
      };

    case actions.RESET_LOADING:
      return {
        ...state,
        loading: false,
        message: '',
        addUser: { loading: false },
      };
    //

    case actions.GET_PROFILE_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROFILE_SUC:
      return {
        ...state,
        loading: false,
        profile: [action.profiles],
      };
    case actions.GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.FETCH_PROFILEPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_PROFILEPIC_SUC:
      let profileImg = action?.payload.split('/asset/upload/image/')[1];
      return {
        ...state,
        loading: false,
        image: profileImg,
      };
    case actions.FETCH_PROFILEPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.FETCH_COVERPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_COVERPIC_SUC:
      let coverImagefetch = '';
      if (action.payload.includes('/asset/upload/image/'))
        coverImagefetch = action?.payload.split('/asset/upload/image/')[1];
      else {
        coverImagefetch = action.payload.split('/image')[1];
      }
      return {
        ...state,
        loading: false,
        coverImage: coverImagefetch,
      };
    case actions.FETCH_COVERPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.EDIT_PROFILEPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.EDIT_PROFILEPIC_SUC:
      let profileImgEdit = action?.payload.split('/asset/upload/image/')[1];
      return {
        ...state,
        isLoading: false,
        image: profileImgEdit,
      };
    case actions.EDIT_PROFILEPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.EDIT_COVERPIC_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.EDIT_COVERPIC_SUC:
      let coverImgEdit = action?.payload.split(
        process.env.REACT_APP_AMAZON_URL,
      )[1];
      return {
        ...state,
        isLoading: false,
        image: coverImgEdit,
      };
    case actions.EDIT_COVERPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.UPLOAD_FILE_PROFILEPIC_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_FILE_PROFILEPIC_SUC:
      return {
        ...state,
        loading: false,
        // image: action.payload?.imgurl
        // image: {
        //     // url: action.payload.imgurl,
        //     id: action.payload.imgid,
        //     bucketData: action.payload.bucketData
        // }
      };

    case actions.UPLOAD_FILE_PROFILEPIC_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case actions.UPLOAD_PROFILEPIC_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_PROFILEPIC_SUC:
      return {
        ...state,
        loading: false,
        image: action.payload.url,
        // { ...state.image,url: action.payload.url}
      };

    case actions.UPLOAD_PROFILEPIC_FAIL:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actions.UPLOAD_FILE_COVERPIC_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_FILE_COVERPIC_SUC:
      return {
        ...state,
        loading: false,
        // coverImage: action.payload?.imgurl
        // image: {
        //     // url: action.payload.imgurl,
        //     id: action.payload.imgid,
        //     bucketData: action.payload.bucketData
        // }
      };

    case actions.UPLOAD_FILE_COVERPIC_REQ:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actions.UPLOAD_COVERIMG_REQ:
      return {
        ...state,
        loading: false,
      };

    case actions.UPLOAD_COVERIMG_SUC:
      let coverImageUpload = action.payload.split(`/asset/upload/image/`)[1];
      return {
        ...state,
        loading: false,
        coverImage: coverImageUpload,
        // { ...state.image,url: action.payload.url}
      };
    case actions.UPLOAD_COVERIMG_FAIL:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case actions.EDIT_PROFILE_SUC:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default VendorUsersReducer;
