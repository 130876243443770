import {
  Bell,
  Disc,
  Gift,
  HardDrive,
  HelpCircle,
  Home,
  List,
  Package,
  Paperclip,
  Plus,
  Star,
  Truck,
  User,
  Mail,
  Settings,
  MessageCircle,
  DollarSign,
  Map,
  Video,
} from 'react-feather';

import {
  FaStoreAlt,
  FaStore,
  FaImages,
  FaUsers,
  FaShippingFast,
} from 'react-icons/fa';
import { RiAdminLine } from 'react-icons/ri';

import paths from 'route/paths';
export const ADMINMENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards, Widgets',
    Items: [
      {
        title: 'Dashboard',
        icon: Home,
        type: 'link',
        active: true,
        path: '/dashboard',
      },
      {
        title: 'Manage Orders',
        icon: Truck,
        type: 'link',
        active: false,
        path: '/dashboard/orders',
      },
      {
        title: 'Order Leads',
        icon: FaShippingFast,
        type: 'sub',
        active: false,
        path: '/#',
        children: [
          {
            title: 'Overview',
            type: 'link',
            active: false,
            path: '/leads/overview',
            permission: 'ViewLeadCount',
          },
          {
            title: 'Leads',
            type: 'link',
            active: false,
            path: '/leads',
            permission: 'ViewLead',
          },
          {
            title: 'Orders',
            type: 'link',
            active: false,
            path: '/lead-orders',
          },
          {
            title: 'Options',
            type: 'link',
            active: false,
            path: '/leads/options',
            permission: 'CreateLead',
          },
          {
            title: 'Shipping Methods',
            type: 'link',
            active: false,
            path: '/leads/shipping-methods',
            permission: 'ManageShippingMethod',
          },
          {
            title: 'Payment Methods',
            type: 'link',
            active: false,
            path: '/leads/payment-methods',
            permission: 'ManagePaymentMethod',
          },
        ],
      },
      {
        title: 'Video Reviews',
        icon: Video,
        type: 'link',
        active: false,
        path: '/video-reviews',
        permission: 'ViewVideoReview',
      },
      {
        title: 'Currency Rates',
        icon: DollarSign,
        type: 'link',
        active: false,
        path: '/currency-rates',
        permission: 'ViewCurrencyRate',
      },
      {
        title: 'Country Groups',
        icon: Map,
        type: 'link',
        active: false,
        path: '/country-groups',
        permission: 'ViewCountryGroup',
      },
      {
        title: 'Shippings',
        icon: Truck,
        type: 'sub',
        active: false,
        path: '/shippings/province',
        permission: 'ViewShipping',
        children: [
          {
            title: 'Delivery Charges',
            type: 'link',
            active: false,
            path: '/shippings/delivery-charges',
            permission: 'ViewDeliveryChargeRate',
          },
          {
            title: 'Countries',
            type: 'link',
            active: false,
            path: '/shippings/countries',
          },
          {
            title: 'Province',
            type: 'link',
            active: false,
            path: '/shippings/province',
          },
          {
            title: 'Districts',
            type: 'link',
            active: false,
            path: '/shippings/district',
          },
          {
            title: 'Municipality',
            type: 'link',
            active: false,
            path: '/shippings/municipality',
          },
          {
            title: 'Area',
            type: 'link',
            active: false,
            path: '/shippings/area',
          },
        ],
      },
      {
        title: 'FAQs',
        icon: MessageCircle,
        type: 'link',
        active: false,
        path: '/FAQs',
        permission: 'ViewFaq',
      },
      {
        title: 'Products',
        icon: Package,
        type: 'sub',
        active: false,
        path: '/products',
        permission: 'ViewProduct',
        children: [
          {
            title: 'All Product',
            type: 'link',
            active: false,
            path: '/products',
          },
        ],
      },

      // {
      //   title: 'Products',
      //   icon: List,
      //   type: 'link',
      //   active: false,
      //   path: paths.allProduct,
      // },

      // {
      //   title: 'Dynamic Forms',
      //   icon: File,
      //   type: 'sub',
      //   active: false,
      //   path: '#',
      //   children: [
      //     {
      //       title: 'Product Form',
      //       type: 'link',
      //       active: false,
      //       path: '#',
      //       children: [
      //         {
      //           title: 'Add Form Group',
      //           icon: Plus,
      //           type: 'link',
      //           active: false,
      //           path: '/dashboard/form-group/create',
      //         },
      //         {
      //           title: 'Edit Form Group',
      //           icon: Plus,
      //           type: 'link',
      //           active: false,
      //           path: '/dashboard/form-group/edit',
      //         },
      //       ],
      //     },
      //     {
      //       title: 'Vendor Documents',
      //       icon: Home,
      //       type: 'link',
      //       active: true,
      //       path: '#',
      //       children: [
      //         {
      //           title: 'Add a new form',
      //           icon: Home,
      //           type: 'link',
      //           active: true,
      //           path: '/dashboard/vendor-document',
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        title: 'Category',
        icon: List,
        type: 'sub',
        active: false,
        path: '/dashboard/categories',
        permission: 'ViewCategory',
        children: [
          {
            title: 'All Category',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/categories',
          },
          {
            title: 'Add Category',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/category/create',
            permission: 'CreateCategory',
          },
          // {
          //   title: 'Add Multiple Category',
          //   icon: Plus,
          //   type: 'link',
          //   active: false,
          //   path: '/dashboard/category/multiple',
          // },
        ],
      },

      {
        title: 'Brands',
        icon: Disc,
        type: 'sub',
        active: false,
        path: paths.Brands,
        permission: 'ViewBrand',
        children: [
          {
            title: 'All Brands',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: paths.Brands,
          },
          {
            title: 'Add Brands',
            icon: Plus,
            type: 'link',
            active: false,
            path: paths.createBrand,
            permission: 'CreateBrand',
          },
        ],
      },
      // {
      //   title: 'Colors',
      //   icon: Wind,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/colors',
      //   children: [
      //     {
      //       title: 'All Colors',
      //       icon: HardDrive,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/colors',
      //     },
      //     {
      //       title: 'Add Colors',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/color/create',
      //     },
      //   ],
      // },
      // {
      //   title: 'Variants',
      //   icon: Package,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/variants',
      //   children: [
      //     {
      //       title: 'All Variants',
      //       icon: HardDrive,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/variants',
      //     },
      //     {
      //       title: 'Add Variants',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/variant/create',
      //     },
      //   ],
      // },

      {
        title: 'Deals',
        icon: Gift,
        type: 'sub',
        active: false,
        path: paths.Deals,
        permission: 'ViewDeal',
        children: [
          {
            title: 'All Deals',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: paths.Deals,
          },
          {
            title: 'Add Deals',
            icon: Plus,
            type: 'link',
            active: false,
            path: paths.AddDeal,
            permission: 'CreateDeal',
          },
        ],
      },
      // {
      //   title: 'Shops',
      //   icon: FaStore,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/shops',
      //   children: [
      //     // {
      //     //   title: 'All Shops',
      //     //   icon: HardDrive,
      //     //   type: 'link',
      //     //   active: false,
      //     //   path: '/dashboard/shops',
      //     // },
      //     {
      //       title: 'Add Shops',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/shop/create',
      //     },
      //   ],
      // },
      {
        title: 'Banners',
        icon: FaImages,
        type: 'sub',
        active: false,
        permission: 'ViewBanner',
        children: [
          {
            title: 'All Banners',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/banners/all',
          },
          {
            title: 'Add Banner',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/banners/add',
            permission: 'CreateBanner',
          },
        ],
      },
      {
        title: 'Placeholders',
        icon: Package,
        type: 'sub',
        active: false,
        permission: 'ViewPlaceholder',
        children: [
          {
            title: 'All PlaceHolders',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/placeholders',
          },
          {
            title: 'Add PlaceHolder',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/placeholder/create',
            permission: 'CreatePlaceholder',
          },
        ],
      },
      {
        title: 'Site Nav',
        icon: Package,
        type: 'sub',
        active: false,
        permission: 'ViewSiteNav',
        children: [
          {
            title: 'All Site Nav',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/site-navs',
          },
          {
            title: 'Add Site Nav',
            icon: Plus,
            type: 'link',
            active: false,
            permission: 'CreateSiteNav',
            path: '/dashboard/site-nav/create',
          },
        ],
      },

      // @zero: FEATURE NOT NEEDED/no implementation
      // {
      //   title: 'Vendors',
      //   icon: FaStoreAlt,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/vendors',
      //   children: [
      //     {
      //       title: 'All Vendors',
      //       icon: HardDrive,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/vendors',
      //     },
      //     // {
      //     //   title: 'Add Vendors',
      //     //   icon: Plus,
      //     //   type: 'link',
      //     //   active: false,
      //     //   path: '/dashboard/vendor/create',
      //     // },
      //   ],
      // },
      {
        title: 'Manage Coupon',
        icon: Paperclip,
        type: 'sub',
        active: false,
        path: '/dashboard/coupons',
        permission: 'ViewCoupon',
        children: [
          {
            title: 'All Coupons',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/coupons',
          },
          {
            title: 'Add Coupons',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/coupon/create',
            permission: 'CreateCoupon',
          },
        ],
      },

      {
        title: 'Roles',
        icon: RiAdminLine,
        type: 'sub',
        active: true,
        path: '/dashboard/roles',
        permission: 'ViewRole',
        children: [
          {
            title: 'All Roles',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/roles',
          },
          {
            title: 'Add Roles',
            icon: Plus,
            type: 'link',
            active: false,
            path: '/dashboard/role/create',
            permission: 'CreateRole',
          },
        ],
      },
      {
        title: 'Users',
        icon: FaUsers,
        type: 'sub',
        active: false,
        path: '/dashboard/user',
        permission: 'ViewAnyUser',
        children: [
          {
            title: 'System Users',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/system-users',
          },
          {
            title: 'All Customers',
            icon: HardDrive,
            type: 'link',
            active: false,
            path: '/dashboard/users',
          },
          // {
          //   title: 'Add User',
          //   icon: Plus,
          //   type: 'link',
          //   active: false,
          //   path: '/dashboard/user/create',
          // },
        ],
      },
      {
        title: 'Push Notification',
        icon: Bell,
        active: false,
        type: 'link',
        path: '/dashboard/push-notifications',
        permission: 'ViewPushNotification',
      },
      {
        title: 'Email Template',
        icon: Mail,
        type: 'link',
        active: true,
        path: '/email-templates',
        permission: 'ViewEmailTemplate',
      },

      // {
      //   title: 'Transactions',
      //   icon: DollarSign,
      //   type: 'link',
      //   active: false,
      //   path: '/dashboard/transactions/completed',
      // },

      // {
      //   title: ' App Config',
      //   icon: HelpCircle,
      //   type: 'sub',
      //   active: false,
      //   path: '/dashboard/setting',
      //   children: [
      //     {
      //       title: 'App setting ',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/app-setting',
      //     },

      //     {
      //       title: 'Pages ',
      //       icon: Plus,
      //       type: 'link',
      //       active: false,
      //       path: '/dashboard/pages',
      //     },
      //   ],
      // },
      {
        title: 'Settings',
        icon: Settings,
        type: 'sub',
        active: false,
        path: '/dashboard/settings',
        permission: 'ViewSettings',
        children: [
          {
            title: 'All Settings',
            type: 'link',
            active: false,
            path: '/dashboard/settings',
          },
          {
            title: 'Add Settings',
            type: 'link',
            active: false,
            permission: 'CreateSettings',
            path: '/dashboard/addSetting',
          },
        ],
      },
      {
        title: 'Reviews',
        icon: Star,
        type: 'link',
        active: false,
        path: '/dashboard/reviews',
        permission: 'ViewReviews',
      },
      {
        title: 'Profile',
        icon: User,
        type: 'link',
        active: false,
        path: '/dashboard/userprofile',
      },
      // {
      //   title: 'My Plans',
      //   icon: Award,
      //   type: 'link',
      //   active: false,
      //   path: '/dashboard/plans',
      // },
      // {
      //   title: 'Support',
      //   icon: HelpCircle,
      //   type: 'link',
      //   active: false,
      //   path: '/dashboard/support',
      // },
    ],
  },
];
