import { message } from "antd";

const errors = {
  UserDoesNotExists: 'UserDoesNotExists',
  InvalidCredentials: 'Invalid Credentials',
};

export const getErrorMessage = (errorName) => {
  switch (errorName) {
    case errors.InvalidCredentials:
      return 'Username or password invalid.';
    case errors.UserDoesNotExists:
      return 'Username or password invalid.';
    default:
      return errorName;
  }
};

export const errorHandler = (err) => {
  let errMessage = 'Something went wrong';
  if (err?.response) {
    errMessage = err?.response?.data?.error?.message || errMessage;
  }
  message.error(errMessage);
};
