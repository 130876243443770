import axios from '../axios';

const checkCode = (status) => {
  switch (status) {
    case 'confirmed':
      return 'OD_CREATED';
    case 'rejected':
      return 'OD_CANCELLED';
    default:
      return 'OD_STATUS';
  }
};

export const getOrdersApi = ({
  page,
  perPage,
  selectedStatus,
  codeFilter,
  deliveryStatus,
  source,
  status,
  date,
  paymentType,
}) => {
  let query = [];

  if (status) selectedStatus = status;

  if (page) query.push(`filter[limit]=${perPage}`);
  if (perPage) query.push(`filter[skip]=${perPage * (page - 1)}`);
  if (selectedStatus && selectedStatus !== 'all')
    query.push(`filter[where][status]=${selectedStatus}`);
  if (codeFilter) query.push(`filter[where][id]=${codeFilter}`);
  if (deliveryStatus) query.push(`filter[where][status]=${deliveryStatus}`);

  // ! order data by Pathao/Lead/Direct
  if (source !== 'All')
    query.push(`filter[where][source]=${source ? source : 'Direct'}`);
  else query.push(`filter[where][source][neq]=Direct`);
  query.push(`filter[order]=created_on%20DESC`);

  //
  if (date && date?.length)
    query.push(
      `filter[where][createdOn][between][0]=${new Date(
        date[0],
      ).toISOString()}&filter[where][createdOn][between][1]=${new Date(
        date[1],
      ).toISOString()}`,
    );

  if (paymentType) {
    query.push(`filter[where][payment_type]=${paymentType}`);
  }

  return axios.get(`/orders?${query.join('&')}`);
};

export const editOrderApi = (status, id, text) => {
  let object = {
    order_code: checkCode(status),
    status: status,
    remarks: text ? text : `Order has been ${status}`,
  };
  return axios.patch(`/orders/changeStatus/?id=${id}`, object);
};

export const PaymentStatusChangeApi = (id, body) => {
  return axios.patch(`/transaction-history/${id}`, body);
};

export const orderCountApi = () => axios.get(`/orders/count`);

export const ordersCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersDeliveredCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[status]=delivered&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersPendingCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[status]=received&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersActiveCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[status]=confirmed&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersCancelCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?[where][status]=rejected&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersProcessingCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[status]=processing&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersPaidCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[status]=paid&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersOutforDeliveryCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?[where][status]=outForDelivery${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const ordersRejectedCountApi = (storeId = 1, source = 'Direct') =>
  axios.get(
    `/orders/count?where[status]=rejected&where[storeId]=${storeId}${
      source ? `&where[source]=${source}` : '&where[source][neq]=Direct'
    }`,
  );

export const updateOrderByIdApi = (id, data) =>
  axios.patch(`/order/${id}/update`, data);
