import Swal from 'sweetalert2';

let localStorage;

// // If we're testing, use a local storage polyfill
// if (global.process && process.env.NODE_ENV === 'test') {
//     localStorage = require('localStorage')
// } else {
//     // If not, use the browser one
// }
localStorage = window.localStorage;

export function clearToken() {
  localStorage.removeItem('access_token');
}

function getTokenFromStorage(token = 'access_token') {
  return localStorage.getItem(token);
}

export function getToken(token = 'access_token') {
  try {
    return getTokenFromStorage(token);
  } catch (err) {
    clearToken();
    return {};
  }
}

export function getBearerToken() {
  if (getToken()) {
    return 'Bearer ' + getToken();
  }
  return false;
}

export const swalFireTemplate = async (text, callback) => {
  Swal.fire({
    title: `${text}`,
    showCancelButton: true,
    confirmButtonText: 'Yes',
    denyButtonText: `No`,
  }).then((result)=>{
    if (result.isConfirmed) {
      callback()
    }
  })
};

export const getCategoryTree = (id, categoryList) => {
  let tree = [];
  let parentId = id;
  function getId() {
    for (let category of categoryList) {
      if (category.id === parentId) {
        tree.unshift(category.id);
        parentId = category.parentId;
        if (parentId !== 0) {
          getId();
        }
      }
    }
  }
  getId();
  return tree;
};

export const delay = (ms, value) =>
  new Promise((res) => setTimeout(res(value), ms));
