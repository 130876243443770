const entities = '[CURRENCY_RATE]';

const action = {
  SEND_CURRENCY_RATE_REQ: `${entities} SEND_CURRENCY_RATE_REQ`,
  SEND_CURRENCY_RATE_SUC: `${entities} SEND_CURRENCY_RATE_SUC`,
  SEND_CURRENCY_RATE_FAIL: `${entities} SEND_CURRENCY_RATE_FAIL`,

  GET_CURRENCY_RATES_REQ: `${entities} GET_CURRENCY_RATES_REQ`,
  GET_CURRENCY_RATES_SUC: `${entities} GET_CURRENCY_RATES_SUC`,
  GET_CURRENCY_RATES_FAIL: `${entities} GET_CURRENCY_RATES_FAIL`,

  DLT_CURRENCY_RATE_REQ: `${entities} DLT_CURRENCY_RATE_REQ`,
  DLT_CURRENCY_RATE_SUC: `${entities} DLT_CURRENCY_RATE_SUC`,
  DLT_CURRENCY_RATE_FAIL: `${entities} DLT_CURRENCY_RATE_FAIL`,

  EDIT_CURRENCY_RATE_REQ: `${entities} EDIT_CURRENCY_RATE_REQ`,
  EDIT_CURRENCY_RATE_SUC: `${entities} EDIT_CURRENCY_RATE_SUC`,
  EDIT_CURRENCY_RATE_FAIL: `${entities} EDIT_CURRENCY_RATE_FAIL`,

  SEARCH_CURRENCY_RATE_LOCAL_REQ: `${entities} SEARCH_CURRENCY_RATE_LOCAL_REQ`,
  SEARCH_CURRENCY_RATE_LOCAL_SUC: `${entities} SEARCH_CURRENCY_RATE_LOCAL_SUC`,
  SEARCH_CURRENCY_RATE_LOCAL_FAIL: `${entities} SEARCH_CURRENCY_RATE_LOCAL_FAIL`,

  sendCurrencyRateReq: (payload) => ({
    type: action.SEND_CURRENCY_RATE_REQ,
    payload,
  }),

  getCurrencyRatesReq: (payload) => ({
    type: action.GET_CURRENCY_RATES_REQ,
    payload,
  }),

  deleteCurrencyRateReq: (payload) => ({
    type: action.DLT_CURRENCY_RATE_REQ,
    payload,
  }),

  editCurrencyRateReq: (id, data) => ({
    type: action.EDIT_CURRENCY_RATE_REQ,
    id,
    data,
  }),
  searchCurrencyRateReq: (data) => ({
    type: action.SEARCH_CURRENCY_RATE_LOCAL_REQ,
    data,
  }),
};

export default action;
