const entities = '[Statistics]';

const action = {
  COUNT_USER: `${entities} COUNT_USER`,
  COUNT_USER_SUC: `${entities} COUNT_USER_SUC`,
  COUNT_USER_FAIL: `${entities} COUNT_USER_FAIL`,

  COUNT_VENDOR: `${entities} COUNT_VENDOR`,
  COUNT_VENDOR_SUC: `${entities} COUNT_VENDOR_SUC`,
  COUNT_VENDOR_FAIL: `${entities} COUNT_VENDOR_FAIL`,

  COUNT_CATEGORY: `${entities} COUNT_CATEGORY`,
  COUNT_CATEGORY_SUC: `${entities} COUNT_CATEGORY_SUC`,
  COUNT_CATEGORY_FAIL: `${entities} COUNT_CATEGORY_FAIL`,

  COUNT_BRAND: `${entities} COUNT_BRAND`,
  COUNT_BRAND_SUC: `${entities} COUNT_BRAND_SUC`,
  COUNT_BRAND_FAIL: `${entities} COUNT_BRAND_FAIL`,

  COUNT_SHOP: `${entities} COUNT_SHOP`,
  COUNT_SHOP_SUC: `${entities} COUNT_SHOP_SUC`,
  COUNT_SHOP_FAIL: `${entities} COUNT_SHOP_FAIL`,

  COUNT_COUPON: `${entities} COUNT_COUPON`,
  COUNT_COUPON_SUC: `${entities} COUNT_COUPON_SUC`,
  COUNT_COUPON_FAIL: `${entities} COUNT_COUPON_FAIL`,

  COUNT_DEAL: `${entities} COUNT_DEAL`,
  COUNT_DEAL_SUC: `${entities} COUNT_DEAL_SUC`,
  COUNT_DEAL_FAIL: `${entities} COUNT_DEAL_FAIL`,

  COUNT_ORDER: `${entities} COUNT_ORDER`,
  COUNT_ORDER_SUC: `${entities} COUNT_ORDER_SUC`,
  COUNT_ORDER_FAIL: `${entities} COUNT_ORDER_FAIL`,

  COUNT_BANNER: `${entities} COUNT_BANNER`,
  COUNT_BANNER_SUC: `${entities} COUNT_BANNER_SUC`,
  COUNT_BANNER_FAIL: `${entities} COUNT_BANNER_FAIL`,

  COUNT_REVIEW: `${entities} COUNT_REVIEW`,
  COUNT_REVIEW_SUC: `${entities} COUNT_REVIEW_SUC`,
  COUNT_REVIEW_FAIL: `${entities} COUNT_REVIEW_FAIL`,

  COUNT_FAQ: `${entities} COUNT_FAQ`,
  COUNT_FAQ_SUC: `${entities} COUNT_FAQ_SUC`,
  COUNT_FAQ_FAIL: `${entities} COUNT_FAQ_FAIL`,

  LOW_STOCK_MJH: `${entities} LOW_STOCK_MJH`,
  LOW_STOCK_MJH_SUC: `${entities} LOW_STOCK_MJH_SUC`,
  LOW_STOCK_MJH_FAIL: `${entities} LOW_STOCK_MJH_FAIL`,

  LOW_STOCK_STD: `${entities} LOW_STOCK_STD`,
  LOW_STOCK_STD_SUC: `${entities} LOW_STOCK_STD_SUC`,
  LOW_STOCK_STD_FAIL: `${entities} LOW_STOCK_STD_FAIL`,

  LOW_STOCK_STA: `${entities} LOW_STOCK_STA`,
  LOW_STOCK_STA_SUC: `${entities} LOW_STOCK_STA_SUC`,
  LOW_STOCK_STA_FAIL: `${entities} LOW_STOCK_STA_FAIL`,

  countUser: () => ({
    type: action.COUNT_USER,
  }),
  countVendor: () => ({
    type: action.COUNT_VENDOR,
  }),
  countCategory: () => ({
    type: action.COUNT_CATEGORY,
  }),
  countBrand: () => ({
    type: action.COUNT_BRAND,
  }),
  countDeal: () => ({
    type: action.COUNT_DEAL,
  }),
  countCoupon: () => ({
    type: action.COUNT_COUPON,
  }),
  countOrder: () => ({
    type: action.COUNT_ORDER,
  }),
  countShop: () => ({
    type: action.COUNT_SHOP,
  }),
  countBanner: () => ({
    type: action.COUNT_BANNER,
  }),
  countFAQ: () => ({
    type: action.COUNT_FAQ,
  }),
  countReview: () => ({
    type: action.COUNT_REVIEW,
  }),
  lowStockMJH: () => ({
    type: action.LOW_STOCK_MJH,
  }),
  lowStockSTA: () => ({
    type: action.LOW_STOCK_STA,
  }),
  lowStockSTD: () => ({
    type: action.LOW_STOCK_STD,
  }),
};

export default action;
