import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'utils';
import {
  getCurrencyRatesApi,
  deleteCurrencyRateApi,
  editCurrencyRateApi,
  postCurrencyRatesApi,
} from '../../../../api/currency-rates';
import ShowMessage from '../../../../components/Toast/Toast';
import { delay } from '../../../../helper/utility';
import actions from './actions';

function* callSendCurrencyRateReq(action) {
  try {
    let apiResponse = yield call(postCurrencyRatesApi, action.payload);

    let { data, status } = apiResponse;

    yield put({
      type: actions.SEND_CURRENCY_RATE_SUC,
      statusCode: status,
      payload: data,
    });
    delay(1000);
    yield ShowMessage(status, 'Currency rate added successfully.');
    history.push('/currency-rates');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.SEND_CURRENCY_RATE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.SEND_CURRENCY_RATE_FAIL,
      payload: err.message,
    });
  }
}

function* callFetchCurrencyRateReq(action) {
  try {
    let apiResponse = yield call(getCurrencyRatesApi, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.GET_CURRENCY_RATES_SUC,
      statusCode: status,
      data: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_CURRENCY_RATES_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_CURRENCY_RATES_FAIL,
      payload: err.message,
    });
  }
}
function* callDeleteDataReq(action) {
  try {
    let apiResponse = yield call(deleteCurrencyRateApi, action.payload);

    let { status } = apiResponse;

    yield put({
      type: actions.DLT_CURRENCY_RATE_SUC,
      statusCode: status,
      id: action.payload,
    });
    delay(1000);
    yield ShowMessage(status, 'Currency rate deleted successfully.');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.DLT_CURRENCY_RATE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.DLT_CURRENCY_RATE_FAIL,
      payload: err.message,
    });
  }
}
function* callEditCurrencyRateReq(action) {
  try {
    let apiResponse = yield call(editCurrencyRateApi, action.id, action.data);

    let { status } = apiResponse;
    const message = 'Currency rate edited successfully.';
    yield put({
      type: actions.EDIT_CURRENCY_RATE_SUC,
      statusCode: status,
      message,
    });
    delay(1000);
    yield ShowMessage(status, message);
    history.push('/currency-rates');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_CURRENCY_RATE_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_CURRENCY_RATE_FAIL,
      payload: err.message,
    });
  }
}

function* callSearchLocalDataRequest(action) {
  try {
    yield delay(400);

    yield put({
      type: actions.SEARCH_CURRENCY_RATE_LOCAL_SUC,
      data: action.data,
    });
  } catch (error) {
    yield put({
      type: actions.SEARCH_CURRENCY_RATE_LOCAL_FAIL,
      data: 'Error occured while searching Currency rates',
    });
    yield ShowMessage(400, 'Error occured while searching Currency rates');
  }
}

export function* fetchCurrencyRate() {
  yield takeEvery(actions.GET_CURRENCY_RATES_REQ, callFetchCurrencyRateReq);
}

export function* sendData() {
  yield takeEvery(actions.SEND_CURRENCY_RATE_REQ, callSendCurrencyRateReq);
}

export function* deleteData() {
  yield takeEvery(actions.DLT_CURRENCY_RATE_REQ, callDeleteDataReq);
}
export function* editData() {
  yield takeEvery(actions.EDIT_CURRENCY_RATE_REQ, callEditCurrencyRateReq);
}

export function* searchLocalData() {
  yield takeEvery(
    actions.SEARCH_CURRENCY_RATE_LOCAL_REQ,
    callSearchLocalDataRequest,
  );
}

export default function* deliveryChargeSaga() {
  return yield all([
    fork(sendData),
    fork(fetchCurrencyRate),
    fork(deleteData),
    fork(editData),
    fork(searchLocalData),
  ]);
}
