const entities = '[COUNTRY_GROUP]';

const action = {
  SEND_COUNTRY_GROUP_REQ: `${entities} SEND_COUNTRY_GROUP_REQ`,
  SEND_COUNTRY_GROUP_SUC: `${entities} SEND_COUNTRY_GROUP_SUC`,
  SEND_COUNTRY_GROUP_FAIL: `${entities} SEND_COUNTRY_GROUP_FAIL`,

  GET_COUNTRY_GROUPS_REQ: `${entities} GET_COUNTRY_GROUPS_REQ`,
  GET_COUNTRY_GROUPS_SUC: `${entities} GET_COUNTRY_GROUPS_SUC`,
  GET_COUNTRY_GROUPS_FAIL: `${entities} GET_COUNTRY_GROUPS_FAIL`,

  DLT_COUNTRY_GROUP_REQ: `${entities} DLT_COUNTRY_GROUP_REQ`,
  DLT_COUNTRY_GROUP_SUC: `${entities} DLT_COUNTRY_GROUP_SUC`,
  DLT_COUNTRY_GROUP_FAIL: `${entities} DLT_COUNTRY_GROUP_FAIL`,

  EDIT_COUNTRY_GROUP_REQ: `${entities} EDIT_COUNTRY_GROUP_REQ`,
  EDIT_COUNTRY_GROUP_SUC: `${entities} EDIT_COUNTRY_GROUP_SUC`,
  EDIT_COUNTRY_GROUP_FAIL: `${entities} EDIT_COUNTRY_GROUP_FAIL`,

  SEARCH_COUNTRY_GROUP_LOCAL_REQ: `${entities} SEARCH_COUNTRY_GROUP_LOCAL_REQ`,
  SEARCH_COUNTRY_GROUP_LOCAL_SUC: `${entities} SEARCH_COUNTRY_GROUP_LOCAL_SUC`,
  SEARCH_COUNTRY_GROUP_LOCAL_FAIL: `${entities} SEARCH_COUNTRY_GROUP_LOCAL_FAIL`,

  sendCountryGroupReq: (payload) => ({
    type: action.SEND_COUNTRY_GROUP_REQ,
    payload,
  }),

  getCountryGroupsReq: (payload) => ({
    type: action.GET_COUNTRY_GROUPS_REQ,
    payload,
  }),

  deleteCountryGroupReq: (payload) => ({
    type: action.DLT_COUNTRY_GROUP_REQ,
    payload,
  }),

  editCountryGroupReq: (id, data) => ({
    type: action.EDIT_COUNTRY_GROUP_REQ,
    id,
    data,
  }),
  searchCountryGroupReq: (data) => ({
    type: action.SEARCH_COUNTRY_GROUP_LOCAL_REQ,
    data,
  }),
};

export default action;
