const entities = '[deals]';

const action = {
    
    SEND_DEALS_REQ: `${entities} SEND_DEALS_REQ`,
    SEND_DEALS_SUC: `${entities} SEND_DEALS_SUC`,
    SEND_DEALS_FAIL: `${entities} SEND_DEALS_FAIL`,

    EDIT_DEALS_REQ: `${entities} EDIT_DEALS_REQ`,
    EDIT_DEALS_SUC: `${entities} EDIT_DEALS_SUC`,
    EDIT_DEALS_FAIL: `${entities} EDIT_DEALS_FAIL`,
  
  
    GET_DEALS_REQ: `${entities} GET_DEALS_REQ`,
    GET_DEALS_SUC: `${entities} GET_DEALS_SUC`,
    GET_DEALS_FAIL: `${entities} GET_DEALS_FAIL`,

    DLT_DEALS_REQ : `${entities} DLT_DEALS_REQ`,
    DLT_DEALS_SUC: `${entities} DLT_DEALS_SUC`,
    DLT_DEALS_FAIL : `${entities} DLT_DEALS_FAIL`,
  
  sendDealsReq: (payload) => ({
    type:action.SEND_DEALS_REQ,
    payload,
  }),
  editDealsReq: (id,payload) => ({
    type:action.EDIT_DEALS_REQ,
    id,
    payload,
  }),

  getDealsReq: (payload) => ({
    type: action.GET_DEALS_REQ,
    payload,
  }),
  
  dltDealsReq: (payload) => ({
    type : action.DLT_DEALS_REQ,
    payload,
  }) 

 
};

export default action;


