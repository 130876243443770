const action = {
  GET_ORDER_REQ: 'GET_ORDER_REQ',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILED: 'GET_ORDER_FAILED',

  ORDER_COUNT_REQ: 'ORDER_COUNT_REQ',
  ORDER_COUNT_SUCCESS: 'ORDER_COUNT_SUCCESS',
  ORDER_COUNT_FAILED: 'ORDER_COUNT_FAILED',

  getOrdersReq: (payload) => ({
    type: action.GET_ORDER_REQ,
    payload,
  }),
};

export default action;
