const action = {

  GET_VENDOR_USERS_REQ: 'GET_VENDOR_USERS_REQ',
  GET_VENDOR_USERS_SUC: 'GET_VENDOR_USERS_SUC',
  GET_VENDOR_USERS_FAIL: 'GET_VENDOR_USERS_FAIL',

  fetchVendorUsersReq: (payload) => ({
    type: action.GET_VENDOR_USERS_REQ,
    payload,
  }),

  CREATE_VENDOR_USER_REQ: 'CREATE_VENDOR_USER_REQ',
  CREATE_VENDOR_USER_SUC: 'CREATE_VENDOR_USER_SUC',
  CREATE_VENDOR_USER_FAIL: 'CREATE_VENDOR_USER_FAIL',

  createVendorUserReq: (payload, cb) => ({
    type: action.CREATE_VENDOR_USER_REQ,
    payload,
    cb
  }),

  GET_PROFILE_REQ: 'GET_PROFILE_REQ',
  GET_PROFILE_SUC: 'GET_PROFILE_SUC',
  GET_PROFILE_FAIL: 'GET_PROFILE_FAIL',

  GET_ROLES_REQ: `GET_ROLES_REQ`,
  GET_ROLES_SUC: ` GET_ROLES_SUC`,
  GET_ROLES_FAIL: `GET_ROLES_FAIL`,

  fetchProfileReq: (payload) => ({
    type: action.GET_PROFILE_REQ,
    payload,
  }),

  RESET_LOADING: 'RESET_LOADING',

  resetLoading: () => ({
    type: action.RESET_LOADING,
  }),

  getRole: () => ({
    type: action.GET_ROLES_REQ,
  }),

  editProfile: (payload) => ({
    type: action.EDIT_PROFILE_REQ,
    payload,
  }),

  fetchCoverPicReq: (payload) => ({
    type: action.FETCH_COVERPIC_REQ,
    payload,
  }),

  editProfilePicReq: (payload) => ({
    type: action.EDIT_PROFILEPIC_REQ,
    payload,
  }),

  uploadFileProfileReq: (payload) => ({
    //profile piture file
    type: action.UPLOAD_FILE_PROFILEPIC_REQ,
    payload,
  }),

  uploadFileCoverReq: (payload) => ({
    //cover picture file of profile
    type: action.UPLOAD_FILE_COVERPIC_REQ,
    payload,
  }),

  uploadProfilePicReq: (payload) => ({
    type: action.UPLOAD_PROFILEPIC_REQ,
    payload,
  }),

  fetchProfilePicReq: () => ({
    type: action.FETCH_PROFILEPIC_REQ,
  }),

  fetchCoverPicReq: () => ({
    type: action.FETCH_COVERPIC_REQ,
  }),

  uploadCoverPicReq: (payload) => ({
    type: action.UPLOAD_COVERIMG_REQ,
    payload,
  }),
};

export default action;
