import { put, call, takeEvery,all,fork } from "redux-saga/effects";
import actions from "./actions";
import {countUserApi, countFAQApi,countReviewApi, countVendorApi, countBannerApi, countBrandApi, countCategoryApi, countCouponApi, countDealApi, countOrderApi, countShopApi} from 'api/statistics';

function* countUserReq(action) {
    try {
        let apiResponse = yield call(countUserApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_USER_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_USER_FAIL,
            payload: err.message
        });
    }
}

export function* countUser() {
    yield takeEvery(actions.COUNT_USER, countUserReq);
}

function* countReviewReq(action) {
    try {
        let apiResponse = yield call(countReviewApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_USER_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_USER_FAIL,
            payload: err.message
        });
    }
}

function* countFAQReq(action) {
    try {
        let apiResponse = yield call(countFAQApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_USER_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_USER_FAIL,
            payload: err.message
        });
    }
}

export function* countReview() {
    yield takeEvery(actions.COUNT_REVIEW, countReviewReq);
}

export function* countFAQ() {
    yield takeEvery(actions.COUNT_FAQ, countFAQReq);
}

function* countVendorReq(action) {
    try {
        let apiResponse = yield call(countVendorApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_VENDOR_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_VENDOR_FAIL,
            payload: err.message
        });
    }
}

export function* countVendor() {
    yield takeEvery(actions.COUNT_VENDOR, countVendorReq);
}

function* countCategoryReq(action) {
    try {
        let apiResponse = yield call(countCategoryApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_CATEGORY_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_CATEGORY_FAIL,
            payload: err.message
        });
    }
}

export function* countCategory() {
    yield takeEvery(actions.COUNT_CATEGORY, countCategoryReq);
}


function* countBrandReq(action) {
    try {
        let apiResponse = yield call(countBrandApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_BRAND_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_BRAND_FAIL,
            payload: err.message
        });
    }
}

export function* countBrand() {
    yield takeEvery(actions.COUNT_BRAND, countBrandReq);
}


function* countDealReq(action) {
    try {
        let apiResponse = yield call(countDealApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_DEAL_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_DEAL_FAIL,
            payload: err.message
        });
    }
}

export function* countDeal() {
    yield takeEvery(actions.COUNT_DEAL, countDealReq);
}

function* countCouponReq(action) {
    try {
        let apiResponse = yield call(countCouponApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_COUPON_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_COUPON_FAIL,
            payload: err.message
        });
    }
}

export function* countCoupon() {
    yield takeEvery(actions.COUNT_COUPON, countCouponReq);
}

function* countOrderReq(action) {
    try {
        let apiResponse = yield call(countOrderApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_ORDER_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        
        let error = err.error;
        if(error){
            yield put({
                type: actions.COUNT_ORDER_FAIL,
                payload: error
            });
        }
        else{
            yield put({
                type: actions.COUNT_ORDER_FAIL,
                payload: err
            });
        }
    }
}

export function* countOrder() {
    yield takeEvery(actions.COUNT_ORDER, countOrderReq);
}

function* countShopReq(action) {
    try {
        let apiResponse = yield call(countShopApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_SHOP_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_SHOP_FAIL,
            payload: err.message
        });
    }
}

export function* countShop() {
    yield takeEvery(actions.COUNT_SHOP, countShopReq);
}

function* countBannerReq(action) {
    try {
        let apiResponse = yield call(countBannerApi, action.payload);
        let { data, status } = apiResponse;
        yield put({
            type: actions.COUNT_BANNER_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.COUNT_BANNER_FAIL,
            payload: err.message
        });
    }
}

export function* countBanner() {
    yield takeEvery(actions.COUNT_BANNER, countBannerReq);
}

function* countStockMJHReq(action) {
    try {
        let apiResponse = yield call(countStockMJH, action.payload);
        let { data, status } = apiResponse;
        console.log(data);
        yield put({
            type: actions.LOW_STOCK_MJH_SUC,
            statusCode: status,
            payload: data
        });
    } catch (err) {
        yield put({
            type: actions.LOW_STOCK_MJH_FAIL,
            payload: err.message
        });
    }
}

export function* countStockMJH() {
    yield takeEvery(actions.COUNT_BANNER, countStockMJHReq);
}

export default function* () {
    return yield all([
        fork(countUser),
        fork(countVendor),
        fork(countCategory),
        fork(countBrand),
        fork(countDeal),
        fork(countCoupon),
        fork(countOrder),
        fork(countShop),
        fork(countBanner),
        fork(countFAQ),
        fork(countReview),
        fork(countStockMJH),
    ])
}
