import axios from '../axios';

export const postDeliveryChargesApi = (options) => {
  return axios.post('/delivery-region-charges', options);
};

export const getDeliveryChargesApi = ({ page, perpage, status, search }) => {
  let query = [];
  if (page) query.push(`filter[limit]=${perpage}`);
  if (perpage) query.push(`filter[skip]=${perpage * (page - 1)}`);
  if (search) {
    let searchQuery = new RegExp(search, 'gi');
    query.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  if (status) query.push(`filter[where][isActive]=${status}`);
  return axios.get(`/delivery-region-charges?${query.join('&')}`);
};
export const deleteDeliveryChargeApi = async (id) => {
  const { status } = await axios.delete(`/delivery-region-charges/${id}`);
  return status === 204 || status === 200;
};

export const editDeliveryChargeApi = (id, data) => {
  return axios.patch(`/delivery-region-charges/${id}`, data);
};
export const getDeliveryChargeCount = () => {
  return axios.get('/delivery-region-charges/count');
};
