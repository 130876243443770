import axios from 'axios';
import Axios from '../axios';
import { APP_CONFIG } from 'app/config';
const endpoint = '/banners';

export const createApi = (payload) => {
  return Axios.post(endpoint, payload);
};

export const updateApi = (id, payload) => {
  return Axios.patch(`${endpoint}/${id}`, payload);
};

export const readApi = (payload) => {
  let query = [];
  if (payload) {
    let { id, page, pageSize, status, searchText, bannerType } = payload;
    if (page) query.push(`filter[limit]=${pageSize}`);
    if (pageSize) query.push(`filter[skip]=${pageSize * (page - 1)}`);
    if (status) query.push(`filter[where][isActive]=${status}`);
    if (bannerType) query.push(`filter[where][agent]=${bannerType}`);
    if (searchText) {
      let searchQuery = new RegExp(searchText, 'gi');
      query.push(`filter[where][buttonTitle][regexp]=${searchQuery}`);
    }
  }

  return Axios.get(`${endpoint}?${query.join('&')}`);
};

export const deleteApi = (id) => {
  return Axios.delete(`${endpoint}/${id}`);
};

export const getProducts = (val) =>{
  const query = [];
  val && query.push(`filter[where][or][0][name][regexp]=/${val}/gi&filter[where][or][1][keywords][regexp]=/${val}/gi`);
  return axios.get(`${APP_CONFIG.API_BASE_URL}/products?${query.join('&')}&filter[limit]=10`)
}

