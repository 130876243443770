import axios from '../axios';

export const getCategoryApi = (options) => {
  return axios.get('/categories', options);
};

export const getCategoryByIdApi = (id) => {
  return axios.get(`/categories/${id}`);
};

export const getDeepLinkedCategoryByIdApi = () => {
  return axios.get(
    '/frontend/category-list?filter[where][forWeb]=true&filter[where][forMobile]=true',
  );
};

export const filterCategoryApi = (options) => {
  let { name, page, perpage } = options;
  let query = [];
  if (page) query.push(`filter[limit]=${Number(perpage)}`);
  if (perpage) query.push(`filter[skip]=${Number(perpage * (page - 1))}`);
  if (name) {
    let caseName = new RegExp(name, 'gi');
    query.push(`filter[where][name][regexp]=${caseName}`);
  }
  return axios.get(`/categories?${query.join('&')}`);
};
