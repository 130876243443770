// import axios from 'axios';
import Axios from '../axios';

export const createUserApi = (body) => {
  return Axios.post('/register/create-user', body);
};

export const getSytemUsers = ({ query, page, perPage, status }) => {
  const filterArr = [];
  // filterArr.push('filter[where][user_role]=["vendor"]');
  filterArr.push('filter[where][user_role][neq]=["customer"]');
  const currentPage = page || 1;
  const limit = perPage || 10;
  if (query) {
    filterArr.push(
      `filter[where][firstName][regexp]=${query}&filter[where][lastName][regexp]=${query}`,
    );
  }

  if (page) filterArr.push(`filter[skip]=${(currentPage - 1) * limit}`);
  filterArr.push(`filter[limit]=${limit}`);

  if (status) filterArr.push(`filter[where][isActive]=${status}`);

  return Axios.get(`/system-users?${filterArr.join('&')}`);
};

export const updateUserById = (id) => {
  return Axios.get('/users?filter[where][user_role]=["vendor"]');
};

export const deleteUserByIdApi = (id) => {
  return Axios.delete(`/users/${id}`);
};

export const updateUserRole = (id, data) => {
  return Axios.patch(`/user/${id}/update-role`, data);
};
export const updateUserPassword = (id, data) => {
  return Axios.patch(`/user/${id}/update-password`, data);
};

export const getCurrentUserPermissions = () => {
  return Axios.get('/users/me/permissions');
};

// all sysmte users
// filter[where][isActive]=
export const getSytemUsersList = () => {
  return Axios.get(
    '/system-users?filter[where][user_role][neq]=["customer"]&filter[where][isActive]=true',
  );
};
