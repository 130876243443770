const apiUrl = 'https://mainapi.shreekesh.com/';
// const apiUrl = 'http://localhost:5001/';

const prod = {
  APP_NAME: 'Saptarishi',
  MAIL: 'admin@shreekesh.com',
  appName: 'Saptarishi',
  API_BASE_URL: apiUrl,
  baseURL: apiUrl,
  AMAZON_URL: 'https://shreekesh-asset.s3.ap-southeast-1.amazonaws.com',
  assetURL: 'https://shreekesh-asset.s3.ap-southeast-1.amazonaws.com/',
  httpAssetURL: `${apiUrl}/asset/upload/image/`,
  WEBSITE: 'www.shreekesh.com',
};

export const APP_CONFIG = prod;
