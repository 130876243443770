import axios from '../axios';

export const getReviewsApi = (page, pageSize,filter) => {
    const {date,product,rating} = filter;
    let array = [];
    if(product){
        // let query = new RegExp(product,'gi')
        array.push(`filter[where][product][name][regexp]=${product}`)
    }
    if(page) array.push(`filter[limit]=${pageSize}`)
    if(pageSize) array.push(`filter[skip]=${pageSize*(page-1)}`);
    if(date) array.push(`filter[where][createdOn][between][0]=${new Date(date).toISOString()}&filter[where][createdOn][between][1]=${new Date(date+86399999).toISOString()}`);
    
    if(rating) array.push(`filter[where][rating]=${rating}`)
    array.push(`&filter[order]=createdOn%20DESC`)

    return axios.get(`/product-review?${array.join('&')}`);
}

export const postMessageApi=(data)=> {
    return axios.post('/faq/answer',data)
}