import actions from './actions';

const initialState = {
  loading: false,
  message: '',
  data: [],
  result: [],
  getData: {
    loading: false,
  },
};

const CurrencyRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_CURRENCY_RATE_LOCAL_SUC:
      return {
        ...state,
        result: state.data.filter(
          (v) =>
            v.name && v.name.toLowerCase().includes(action.data.toLowerCase()),
        ),
      };
    case actions.SEND_CURRENCY_RATE_REQ:
      return {
        ...state,
        sendData: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case actions.SEND_CURRENCY_RATE_SUC:
      return {
        ...state,
        sendData: {
          loading: false,
          success: true,
          error: false,
          data: action.payload,
        },
      };
    case actions.SEND_CURRENCY_RATE_FAIL:
      return {
        ...state,
        sendData: {
          loading: false,
          success: false,
          error: true,
          data: action.payload,
        },
      };
    case actions.GET_CURRENCY_RATES_REQ:
      return {
        ...state,
        getData: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case actions.GET_CURRENCY_RATES_SUC:
      return {
        ...state,
        getData: {
          loading: false,
          success: true,
          error: false,
          data: action.payload,
        },
        data: [...action.data],
      };
    case actions.GET_CURRENCY_RATES_FAIL:
      return {
        ...state,
        getData: {
          loading: false,
          success: false,
          error: true,
        },
        message: action.payload,
      };
    case actions.DLT_CURRENCY_RATE_REQ:
      return {
        ...state,
        deleteData: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case actions.DLT_CURRENCY_RATE_SUC:
      return {
        ...state,
        deleteData: {
          loading: false,
          success: true,
          error: false,
        },
        data: state.data.filter((brand) => brand.id !== action.id),
      };
    case actions.DLT_CURRENCY_RATE_FAIL:
      return {
        ...state,
        deleteData: {
          loading: false,
          success: false,
          error: true,
        },
        message: actions.payload,
      };
    case actions.EDIT_CURRENCY_RATE_REQ:
      return {
        ...state,
        editData: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case actions.EDIT_CURRENCY_RATE_SUC:
      return {
        ...state,
        editData: {
          loading: false,
          success: true,
          error: false,
        },
        message: actions.message,
      };
    case actions.EDIT_CURRENCY_RATE_FAIL:
      return {
        ...state,
        editData: {
          loading: false,
          success: false,
          error: true,
        },
        message: actions.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default CurrencyRateReducer;
