import React from 'react';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  isAuthenticated,
  layout: Layout,
  redirectTo,
  ...rest
}) => {
  // TODO! use loading to wait authencation state
  // console.log('isAuthenticated? ', isAuthenticated);
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isAuthenticated ? (
          Layout ? (
            <Layout>
              <Component {...props} {...rest} key={path} />
            </Layout>
          ) : (
            <Component />
          )
        ) : (
          <>
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
