import axios from '../axios';

export const postCategoryApi = (options) => {
  return axios.post('/categories', options);
};

export const getCategoryApi = (options) => {
  return axios.get('/categories', {
    params: {
      filter: options,
    },
  });
};

export const countCategoryApi = (options) => {
  return axios.get('/categories/count', {
    params: {
      filter: options,
    },
  });
};

export const deleteCategoryApi = (id) => {
  return axios.delete(`/categories/${id}`);
};

export const editCategoryApi = (id, data) => {
  return axios.patch(`/categories/${id}`, data);
};

export const fetchFormattedCategory = () => {
  return axios.get(
    // '/frontend/category-list?filter[where][forWeb]=true&filter[where][forMobile]=true',
    '/frontend/category-list',
  );
};
