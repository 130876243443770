import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'utils';
import {
  getVideoReviewsApi,
  deleteVideoReviewApi,
  editVideoReviewApi,
  postVideoReviewsApi,
} from '../../../../api/video-reviews';
import ShowMessage from '../../../../components/Toast/Toast';
import { delay } from '../../../../helper/utility';
import actions from './actions';

function* callSendVideoReviewReq(action) {
  try {
    let apiResponse = yield call(postVideoReviewsApi, action.payload);

    let { data, status } = apiResponse;

    yield put({
      type: actions.SEND_VIDEOREVIEW_SUC,
      statusCode: status,
      payload: data,
    });
    delay(1000);
    yield ShowMessage(status, 'Video Review added successfully.');
    history.push('/video-reviews');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.SEND_VIDEOREVIEW_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.SEND_VIDEOREVIEW_FAIL,
      payload: err.message,
    });
  }
}

function* callFetchVideoReviewReq(action) {
  try {
    let apiResponse = yield call(getVideoReviewsApi, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.GET_VIDEOREVIEWS_SUC,
      statusCode: status,
      data: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_VIDEOREVIEWS_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_VIDEOREVIEWS_FAIL,
      payload: err.message,
    });
  }
}
function* callDeleteDataReq(action) {
  try {
    let apiResponse = yield call(deleteVideoReviewApi, action.payload);

    let { status } = apiResponse;

    yield put({
      type: actions.DLT_VIDEOREVIEW_SUC,
      statusCode: status,
      id: action.payload,
    });
    delay(1000);
    yield ShowMessage(status, 'Video Review deleted successfully.');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.DLT_VIDEOREVIEW_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.DLT_VIDEOREVIEW_FAIL,
      payload: err.message,
    });
  }
}
function* callEditVideoReviewReq(action) {
  try {
    let apiResponse = yield call(editVideoReviewApi, action.id, action.data);

    let { status } = apiResponse;
    const message = 'Video Review edited successfully.';
    yield put({
      type: actions.EDIT_VIDEOREVIEW_SUC,
      statusCode: status,
      message,
    });
    delay(1000);
    yield ShowMessage(status, message);
    history.push('/video-reviews');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_VIDEOREVIEW_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_VIDEOREVIEW_FAIL,
      payload: err.message,
    });
  }
}

export function* fetchVideoReview() {
  yield takeEvery(actions.GET_VIDEOREVIEWS_REQ, callFetchVideoReviewReq);
}

export function* sendData() {
  yield takeEvery(actions.SEND_VIDEOREVIEW_REQ, callSendVideoReviewReq);
}

export function* deleteData() {
  yield takeEvery(actions.DLT_VIDEOREVIEW_REQ, callDeleteDataReq);
}
export function* editData() {
  yield takeEvery(actions.EDIT_VIDEOREVIEW_REQ, callEditVideoReviewReq);
}

export default function* deliveryChargeSaga() {
  return yield all([
    fork(sendData),
    fork(fetchVideoReview),
    fork(deleteData),
    fork(editData),
  ]);
}
