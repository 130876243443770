import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { getOrdersApi } from '../../../../api/fetchOrder';
import actions from './actions';

function* callFetchOrderReq(action) {
  try {
    let apiResponse = yield call(getOrdersApi, action.payload);
    let {
      data: { orderItems, totalCount },
    } = apiResponse;
    yield put({
      type: actions.GET_ORDER_SUCCESS,
      payload: [...orderItems],
    });
    yield put({ type: actions.ORDER_COUNT_SUCCESS, count: totalCount });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_ORDER_FAILED,
        payload: err.reponse.message,
      });
    }
    yield put({
      type: actions.GET_ORDER_FAILED,
      payload: err.message,
    });
  }
}

export function* fetchOrder() {
  yield takeEvery(actions.GET_ORDER_REQ, callFetchOrderReq);
}

export default function* () {
  return yield all([fork(fetchOrder)]);
}
