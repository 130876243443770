import axios from '../axios';

export const postVideoReviewsApi = (options) => {
  return axios.post('/product-video-reviews', options);
};

export const getVideoReviewsApi = ({ page, perpage, status, search }) => {
  let query = [];
  if (page) query.push(`filter[limit]=${perpage}`);
  if (perpage) query.push(`filter[skip]=${perpage * (page - 1)}`);
  if (search) {
    let searchQuery = new RegExp(search, 'gi');
    query.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  if (status) query.push(`filter[where][isActive]=${status}`);
  return axios.get(`/product-video-reviews?${query.join('&')}`);
};
export const deleteVideoReviewApi = async (id) => {
  const { status } = await axios.delete(`/product-video-reviews/${id}`);
  return status === 204 || status === 200;
};

export const editVideoReviewApi = (id, data) => {
  return axios.patch(`/product-video-reviews/${id}`, data);
};

export const getVideoReviewCount = () => {
  return axios.get('/product-video-reviews/count');
};
