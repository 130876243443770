import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { history } from 'utils';
import {
  getCountryGroupsApi,
  deleteCountryGroupApi,
  editCountryGroupApi,
  postCountryGroupsApi,
} from '../../../../api/countries/country-groups';
import ShowMessage from '../../../../components/Toast/Toast';
import { delay } from '../../../../helper/utility';
import actions from './actions';

function* callSendCountryGroupReq(action) {
  try {
    let apiResponse = yield call(postCountryGroupsApi, action.payload);

    let { data, status } = apiResponse;

    yield put({
      type: actions.SEND_COUNTRY_GROUP_SUC,
      statusCode: status,
      payload: data,
    });
    delay(1000);
    yield ShowMessage(status, 'Country Group added successfully.');
    history.push('/country-groups');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.SEND_COUNTRY_GROUP_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.SEND_COUNTRY_GROUP_FAIL,
      payload: err.message,
    });
  }
}

function* callFetchCountryGroupReq(action) {
  try {
    let apiResponse = yield call(getCountryGroupsApi, action.payload);
    let { data, status } = apiResponse;

    yield put({
      type: actions.GET_COUNTRY_GROUPS_SUC,
      statusCode: status,
      data: data,
    });
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.GET_COUNTRY_GROUPS_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.GET_COUNTRY_GROUPS_FAIL,
      payload: err.message,
    });
  }
}
function* callDeleteDataReq(action) {
  try {
    let apiResponse = yield call(deleteCountryGroupApi, action.payload);

    let { status } = apiResponse;

    yield put({
      type: actions.DLT_COUNTRY_GROUP_SUC,
      statusCode: status,
      id: action.payload,
    });
    delay(1000);
    yield ShowMessage(status, 'Country Group deleted successfully.');
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.DLT_COUNTRY_GROUP_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.DLT_COUNTRY_GROUP_FAIL,
      payload: err.message,
    });
  }
}
function* callEditCountryGroupReq(action) {
  try {
    let apiResponse = yield call(editCountryGroupApi, action.id, action.data);

    let { status } = apiResponse;
    const message = 'Country Group edited successfully.';
    yield put({
      type: actions.EDIT_COUNTRY_GROUP_SUC,
      statusCode: status,
      message,
    });
    delay(1000);
    yield ShowMessage(status, message);
    history.push('/country-groups');
    history.go(0);
  } catch (err) {
    if (err && err?.response) {
      yield put({
        type: actions.EDIT_COUNTRY_GROUP_FAIL,
        payload: err.response.message,
      });
    }
    yield put({
      type: actions.EDIT_COUNTRY_GROUP_FAIL,
      payload: err.message,
    });
  }
}

function* callSearchLocalDataRequest(action) {
  try {
    yield delay(400);

    yield put({
      type: actions.SEARCH_COUNTRY_GROUP_LOCAL_SUC,
      data: action.data,
    });
  } catch (error) {
    yield put({
      type: actions.SEARCH_COUNTRY_GROUP_LOCAL_FAIL,
      data: 'Error occured while searching Country Groups',
    });
    yield ShowMessage(400, 'Error occured while searching Country Groups');
  }
}

export function* fetchCountryGroup() {
  yield takeEvery(actions.GET_COUNTRY_GROUPS_REQ, callFetchCountryGroupReq);
}

export function* sendData() {
  yield takeEvery(actions.SEND_COUNTRY_GROUP_REQ, callSendCountryGroupReq);
}

export function* deleteData() {
  yield takeEvery(actions.DLT_COUNTRY_GROUP_REQ, callDeleteDataReq);
}
export function* editData() {
  yield takeEvery(actions.EDIT_COUNTRY_GROUP_REQ, callEditCountryGroupReq);
}

export function* searchLocalData() {
  yield takeEvery(
    actions.SEARCH_COUNTRY_GROUP_LOCAL_REQ,
    callSearchLocalDataRequest,
  );
}

export default function* deliveryChargeSaga() {
  return yield all([
    fork(sendData),
    fork(fetchCountryGroup),
    fork(deleteData),
    fork(editData),
    fork(searchLocalData),
  ]);
}
