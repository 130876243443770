import axios from '../axios';

export const postCurrencyRatesApi = (options) => {
  return axios.post('/currency-rates', options);
};

export const getCurrencyRatesApi = ({ page, perpage, status, search }) => {
  let query = [];
  if (page) query.push(`filter[limit]=${perpage}`);
  if (perpage) query.push(`filter[skip]=${perpage * (page - 1)}`);
  if (search) {
    let searchQuery = new RegExp(search, 'gi');
    query.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  if (status) query.push(`filter[where][isActive]=${status}`);
  return axios.get(`/currency-rates/all?${query.join('&')}`);
};
export const deleteCurrencyRateApi = async (id) => {
  const { status } = await axios.delete(`/currency-rate/${id}`);
  return status === 204 || status === 200;
};

export const editCurrencyRateApi = (id, data) => {
  return axios.patch(`/currency-rate/${id}`, data);
};

export const getCurrencyRateCount = () => {
  return axios.get('/currency-rates/count');
};
