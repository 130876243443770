import actions from './actions';

const initialState = {
  loading: false,
  message: '',
  orders: [],
  count: 0,
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ORDER_REQ:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: [...action.payload],
      };
    case actions.GET_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case actions.ORDER_COUNT_SUCCESS:
      return {
        ...state,
        count: action.count,
      };
    default:
      return {
        ...state,
      };
  }
};

export default OrderReducer;
