import axios from '../axios';

export const postDealApi = (options) => {
  return axios.post('/deals', options);
};

export const editDealApi = (id, data) => {
  return axios.patch(`/deals/${id}`, data);
};
export const getDealsApi = (payload) => {
  let query = [];
  if (payload) {
    let { page, pageSize, type, active, search } = payload;
    if (page) query.push(`filter[limit]=${pageSize}`);
    if (pageSize) query.push(`filter[skip]=${pageSize * (page - 1)}`);
    if (type) query.push(`filter[where][discountType]=${type}`);
    if (active) query.push(`filter[where][isActive]=${active}`);
    if (search) {
      let searchQuery = new RegExp(search, 'gi');
      query.push(`filter[where][name][regexp]=${searchQuery}`);
    }
  }

  return axios.get(`/deals?${query.join('&')}`);
};
export const deleteDealApi = (id) => {
  return axios.delete(`/deals/${id}`);
};

export const getDealApi = (id) => {
  if (id) {
    return axios.get(`/deals/${id}`);
  } else {
    return axios.get(`/deals`);
  }
};
