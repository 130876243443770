import axios from '../axios';

export const postCountryGroupsApi = (options) => {
  return axios.post('/country-groups', options);
};

export const getCountryGroupsApi = ({ page, perpage, status, search }) => {
  let query = [];
  if (page) query.push(`filter[limit]=${perpage}`);
  if (perpage) query.push(`filter[skip]=${perpage * (page - 1)}`);
  if (search) {
    let searchQuery = new RegExp(search, 'gi');
    query.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  if (status) query.push(`filter[where][isActive]=${status}`);
  return axios.get(`/country-groups/all?${query.join('&')}`);
};
export const deleteCountryGroupApi = async (id) => {
  const { status } = await axios.delete(`/country-group/${id}`);
  return status === 204 || status === 200;
};

export const editCountryGroupApi = (id, data) => {
  return axios.patch(`/country-group/${id}`, data);
};

export const getCountryGroupCount = () => {
  return axios.get('/country-groups/count');
};
