import axios from '../axios';
import { getBearerToken } from 'helper/utility';

export const OrderSource = {
  Lead: 'Lead', //
  Pathao: 'Pathao', //
  Direct: 'Direct', // direct order from user
};

export const orderLeadsCountApi = (
  leadType = 'Inquiry',
  date,
  creatorId,
  shippingMethod,
) => {
  const query = [];
  if (date && date.length) {
    query.push(
      `[where][createdOn][between][0]=${new Date(
        date[0],
      ).toISOString()}&[where][createdOn][between][1]=${new Date(
        date[1],
      ).toISOString()}`,
    );
  }
  if (creatorId) query.push(`[where][creatorId]=${creatorId}`);

  if (shippingMethod) {
    const filterValue = shippingMethod === 'None' ? null : shippingMethod;
    query.push(`[where][shippingMethod]=${filterValue}`);
  }

  if (leadType === 'all')
    return axios.get(`/orders-leads/count?${query.join('&')}`);

  return axios.get(
    `/orders-leads/count?[where][lead_type]=${leadType}&${query.join('&')}`,
  );
};

export const orderLeadsFollowUpCountApi = (followUp, date, creatorId) => {
  const query = [];
  if (followUp) {
    query.push(`[where][followup]=${followUp}`);
  } else {
    query.push(`[where][followup][eq]=null`);
  }

  if (date && date.length) {
    query.push(
      `[where][createdOn][between][0]=${new Date(
        date[0],
      ).toISOString()}&[where][createdOn][between][1]=${new Date(
        date[1],
      ).toISOString()}`,
    );
  }
  if (creatorId) query.push(`[where][creatorId]=${creatorId}`);
  return axios.get(`/orders-leads/count?${query.join('&')}`);
};

export const getOrderLeadsApi = async (filter) => {
  const {
    date,
    code,
    status,
    page,
    perPage,
    emailSearch,
    searchName,
    id,
    searchPhone,
    storeId,
    searchQuery,
    leadType,
    followup,
    source,
    creatorId,
    shippingMethod,
    paymentMethod,
  } = filter;
  let filterArray = [];
  if (perPage) filterArray.push(`&filter[limit]=${perPage}`);
  if (page) filterArray.push(`filter[skip]=${(page - 1) * perPage}`);
  if (code) {
    let searchQuery = new RegExp(code, 'gi');
    filterArray.push(`filter[where][tracking_code][regexp]=${searchQuery}`);
  }
  if (id) {
    filterArray.push(`filter[where][id]=${id}`);
  }
  if (storeId) {
    filterArray.push(`filter[where][storeId]=${storeId}`);
  }
  if (status) {
    if (status === 'delivered') {
      filterArray.push(
        `filter[where][status]=delivered&filter[include][][payment_type]=himalayanBank&filter[include][][payment_type]=esewa&filter[include][][payment_type]=khalti&filter[include][][payment_type]=fonepay`,
      );
    } else {
      filterArray.push(`filter[where][status]=${status}`);
    }
  }
  if (date && date?.length)
    filterArray.push(
      `filter[where][createdOn][between][0]=${new Date(
        date[0],
      ).toISOString()}&filter[where][createdOn][between][1]=${new Date(
        date[1],
      ).toISOString()}`,
    );

  if (searchQuery) {
    let sQuery = '';
    const specialChars = '<>@!#$%^&*()_+[]{}?:;|\'"\\,./~`-=';
    let isMatch = false;
    for (let i = 0; i < specialChars.length; i++) {
      if (searchQuery.includes(specialChars[i])) {
        isMatch = true;
        break;
      }
    }

    if (!isMatch) {
      sQuery = new RegExp(encodeURIComponent(searchQuery), 'gi');
    } else {
      sQuery = encodeURIComponent(searchQuery)
        .replace(/\-/g, '%2D')
        .replace(/\_/g, '%5F')
        .replace(/\./g, '%2E')
        .replace(/\!/g, '%21')
        .replace(/\~/g, '%7E')
        .replace(/\*/g, '%2A')
        .replace(/\'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');
    }

    filterArray.push(
      `filter[where][or][0][email][regexp]=${sQuery}&filter[where][or][1][name][regexp]=${sQuery}` +
        // ${
        //   parseInt(searchQuery)
        //     ? `&filter[where][or][2][orderid][eq]=${parseInt(searchQuery)}`
        //     : ''
        // }
        `${
          parseInt(searchQuery)
            ? `&filter[where][or][3][phone][regexp]=${sQuery}`
            : ''
        }`,
    );
  }

  if (searchName) {
    let searchQuery = new RegExp(searchName, 'gi');
    filterArray.push(`filter[where][name][regexp]=${searchQuery}`);
  }
  filterArray.push(`filter[order]=createdOn%20DESC`);

  if (leadType !== 'all') {
    filterArray.push(`filter[where][lead_type]=${leadType}`);
  } else {
  }

  if (followup) {
    filterArray.push(`filter[where][followup]=${followup}`);
  }

  if (creatorId) {
    filterArray.push(`filter[where][creatorId]=${creatorId}`);
  }

  if (shippingMethod) {
    filterArray.push(`filter[where][shippingMethod]=${shippingMethod}`);
  }

  if (paymentMethod) {
    // filterArray.push(`filter[where][paymentMethod]=${paymentMethod}`);
  }

  let response = await axios.get(`/order-leads?${filterArray.join('&')}`, {
    headers: { Authorization: getBearerToken() },
  });

  return response;
};

// get all order leads
export const getAllOrderLeadsApi = ({ creatorId, leadType, dateRange }) => {
  const query = [];
  if (creatorId) query.push(`filter[where][creatorId]=${creatorId}`);
  if (leadType && leadType !== 'all')
    query.push(`filter[where][lead_type]=${leadType}`);
  if (dateRange && dateRange?.length) {
    query.push(
      `filter[where][createdOn][between][0]=${new Date(
        dateRange[0],
      ).toISOString()}&filter[where][createdOn][between][1]=${new Date(
        dateRange[1],
      ).toISOString()}`,
    );
  }

  return axios.get(`/order-leads/all?${query.join('&')}`);
};

export const editOrderLeadApi = (data) => {
  let object = {
    status: data?.status,
    remarks: `${data.text ? data?.text : data?.status}`,
  };
  return axios.patch(`/orders/changeStatus/?id=${data?.id}`, object, {
    headers: { Authorization: getBearerToken() },
  });
};

export const fetchOrderLeadApiById = (id) => {
  return axios.get(`/orders/${id}`, {
    headers: { Authorization: getBearerToken() },
  });
};

export const searchUserFromExistingOrder = (phone) => {
  return axios.get(`/orders/users/${phone}`);
};

export const getAllProducts = () => {
  return axios.get(`/products`);
};

export const getAllPublishedProducts = () => {
  return axios.get(`/products/published-all`);
};

export const getOrderLeadById = (id) => {
  return axios.get(`/order-leads/${id}`);
};

export const getRemarkLogsByLeadId = (id) => {
  return axios.get(`/order-lead-logs/${id}`);
};
export const saveRemarkLogForLeadApi = (id, data) => {
  return axios.post(`/order-lead-logs/${id}`, data);
};

export const postCreateOrderLead = (data) => {
  return axios.post(`/order-leads`, data);
};

export const saveOrderLeadApi = (leadId, data) => {
  return axios.patch(`/order-leads/${leadId}`, data);
};

export const postCreateOrderLeadWithOrder = (data) => {
  return axios.post(`/order-leads/create-order`, data);
};

export const postPlaceOrderFromLead = (leadId, data) => {
  return axios.post(`/order-leads/place-order/${leadId}`, data);
};

export const deleteOrderLead = (id) => {
  return axios.delete(`/order-leads/${id}`);
};

export function getDeliveryRegionCharges(countryCode) {
  const apiUrl = `/delivery-region-charges/country/${countryCode}`;
  return axios.get(apiUrl);
}

export function getCurrencyRate(currencyCode) {
  const apiUrl = `/currency-rate/currency/${currencyCode}`;
  return axios.get(apiUrl);
}

// overview
export function getLeadsCountByVendorUsers() {
  return axios.get('/order-leads/count-by-creator');
}

export function getLeadTypesCountByVendorUsers(props) {
  const date = props?.date;
  let query = [];
  if (date && date?.length)
    query.push(
      `filter[where][createdOn][between][0]=${new Date(
        date?.[0],
      ).toISOString()}&filter[where][createdOn][between][1]=${new Date(
        date?.[1],
      ).toISOString()}`,
    );
  return axios.get(`/order-leads/count-by-types-creator?${query.join('&')}`);
}

export function getLeadOptionByKey(key) {
  return axios.get(`lead-option/${key}`);
}

export function updateShippingMethodByLeadOrderId(leadId, data) {
  return axios.patch(`/order-leads/update-shipping-method/${leadId}`, data);
}
