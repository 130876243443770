// import './wdyr'; // Why did you render for optimizations
import { ConnectedRouter } from 'connected-react-router';
import React, { Fragment, useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  BrowserRouter,
} from 'react-router-dom';
import Signin from './auth/signin';
import './index.scss';
import AppLayout from './layout/AppLayout';
import actions from './pages/authentication/redux/actions';
import Register from './pages/authentication/register';
import Error404 from './pages/errors/error404';
import { routes } from './route';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { history } from './store/index';
import PrivateRoute from './utils/PrivateRoute';
import ProtectedRoute from './utils/ProtectedRoute';
import 'antd/dist/antd.css';
import PageLoader from './components/PageLoader/PageLoader';
import Icon from 'assets/images/saptarishi-newlogo.png';
import 'sweetalert2/src/sweetalert2.scss';
import { APP_CONFIG } from 'app/config';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1ee5a45952184f2e7d4a1ae883dd2fab@o4506336348012544.ingest.sentry.io/4506358472048640',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Root = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // here
  const auth = useSelector((state) => state.Auth);

  const preLocationStatus = useSelector((state) => state.router.location);

  useEffect(() => {
    // const animation =
    //   localStorage.getItem('animation') ||
    //   ConfigDB.data.router_animation ||
    //   'fade';

    // setAnim(animation);
    // if(localStorage.getItem('access_token')){
    dispatch(actions.getTokenFromStorage());
    // }
  }, []);
  return (
    <BrowserRouter>
      {/* <Loader show={auth.fetchingCurrentUser} /> */}
      <Suspense
        fallback={<PageLoader Logo={Icon} logoAlt={APP_CONFIG.APP_NAME} />}
      >
        <Switch>
          <ProtectedRoute
            isAuthenticated={auth.isLoggedIn}
            path={'/login'}
            // redirectTo={preLocationStatus?.state?.from?.pathname}
            redirectTo={preLocationStatus?.pathname}
            component={Signin}
          />
          <ProtectedRoute
            isAuthenticated={auth.isLoggedIn}
            path={'/signup'}
            component={Register}
          />
          <Route
            path="/"
            exact
            render={() => <Redirect to={location?.state?.from || '/login'} />}
          />
          {routes.map(({ path, exact, Component }, index) => (
            <PrivateRoute
              key={`router-${path}-${index}`}
              path={path}
              exact={exact || false}
              layout={AppLayout}
              component={Component}
              redirectTo={preLocationStatus?.pathname}
              isAuthenticated={auth.isLoggedIn}
            />
          ))}
          {/* <Route component={Error404} /> */}
          <Route path={'*'} component={Error404}></Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
