const entities = '[DELIVERY_CHARGE]';

const action = {
  SEND_DELIVERY_CHARGE_REQ: `${entities} SEND_DELIVERY_CHARGE_REQ`,
  SEND_DELIVERY_CHARGE_SUC: `${entities} SEND_DELIVERY_CHARGE_SUC`,
  SEND_DELIVERY_CHARGE_FAIL: `${entities} SEND_DELIVERY_CHARGE_FAIL`,

  GET_DELIVERY_CHARGES_REQ: `${entities} GET_DELIVERY_CHARGES_REQ`,
  GET_DELIVERY_CHARGES_SUC: `${entities} GET_DELIVERY_CHARGES_SUC`,
  GET_DELIVERY_CHARGES_FAIL: `${entities} GET_DELIVERY_CHARGES_FAIL`,

  DLT_DELIVERY_CHARGE_REQ: `${entities} DLT_DELIVERY_CHARGE_REQ`,
  DLT_DELIVERY_CHARGE_SUC: `${entities} DLT_DELIVERY_CHARGE_SUC`,
  DLT_DELIVERY_CHARGE_FAIL: `${entities} DLT_DELIVERY_CHARGE_FAIL`,

  EDIT_DELIVERY_CHARGE_REQ: `${entities} EDIT_DELIVERY_CHARGE_REQ`,
  EDIT_DELIVERY_CHARGE_SUC: `${entities} EDIT_DELIVERY_CHARGE_SUC`,
  EDIT_DELIVERY_CHARGE_FAIL: `${entities} EDIT_DELIVERY_CHARGE_FAIL`,

  SEARCH_DELIVERY_CHARGE_LOCAL_REQ: `${entities} SEARCH_DELIVERY_CHARGE_LOCAL_REQ`,
  SEARCH_DELIVERY_CHARGE_LOCAL_SUC: `${entities} SEARCH_DELIVERY_CHARGE_LOCAL_SUC`,
  SEARCH_DELIVERY_CHARGE_LOCAL_FAIL: `${entities} SEARCH_DELIVERY_CHARGE_LOCAL_FAIL`,

  sendDeliveryChargeReq: (payload) => ({
    type: action.SEND_DELIVERY_CHARGE_REQ,
    payload,
  }),

  getDeliveryChargesReq: (payload) => ({
    type: action.GET_DELIVERY_CHARGES_REQ,
    payload,
  }),

  deleteDeliveryChargeReq: (payload) => ({
    type: action.DLT_DELIVERY_CHARGE_REQ,
    payload,
  }),

  editDeliveryChargeReq: (id, data) => ({
    type: action.EDIT_DELIVERY_CHARGE_REQ,
    id,
    data,
  }),
  searchDeliveryChargeReq: (data) => ({
    type: action.SEARCH_DELIVERY_CHARGE_LOCAL_REQ,
    data,
  }),
};

export default action;
